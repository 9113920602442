<template>
  <!-- Error page-->
  <div class="misc-wrapper">
    <b-modal id="modal-413" ref="modal-413" centered size="sm" no-close-on-backdrop hide-footer>
      <div class="camera-canvas">
        <div class="misc-inner p-2 p-sm-3">
          <div class="w-100 text-center">
            <h2 class="mb-2">Eita... 😯</h2>
            <h2>Esse arquivo é maior do que podemos surportar.</h2>
            <p class="mb-2">ERROR-413</p>
            <p class="mb-1">Clique no botão abaixo para voltar a página inicial</p>
            <b-button variant="primary" class="mb-1 btn-sm-block" :to="loginRoute()">Tela Inicial</b-button>
            <b-img v-bind="imgerror" fluid :src="imgUrl" alt="ERROR-401 Imagem" />
          </div>
        </div>
      </div>
      <template #modal-footer="{ ok, cancel, Tela }"> </template>
    </b-modal>
  </div>
</template>

<script>
  /* eslint-disable global-require */
  import { BLink, BImg, BButton } from 'bootstrap-vue'
  import { getHomeRouteForLoggedInUser } from '@/auth/utils'

  export default {
    components: {
      BLink,
      BImg,
      BButton,
    },
    data() {
      return {
        downImg: require('@/assets/images/pages/toolarge.png'),
        imgerror: {
          center: true,
          width: 500,
          height: 300,
        },
      }
    },
    computed: {
      imgUrl() {
        this.downImg = require('@/assets/images/pages/toolarge.png')
        return this.downImg
      },
    },
    mounted() {
      this.$refs['modal-413'].show()
    },
    methods: {
      loginRoute() {
        const user = this.$jwtDecode(localStorage.getItem('userData')).userData
        return getHomeRouteForLoggedInUser(user ? user.role : null)
      },
    },
  }
</script>

<style lang="scss">
  @import '@core/scss/vue/pages/page-misc.scss';
</style>
